import {
    start,
    navigateToUrl,
    getMountedApps,
    unloadApplication,
    registerApplication,
} from 'single-spa';
import {
    constructRoutes,
    constructApplications,
    constructLayoutEngine,
} from 'single-spa-layout';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.variable.min.css';
import { EventBus } from '@gf-tech/utils';
import { location as _location } from '@qietuzi/utils';

import './app.css';
import ws from './ws';
import {
    store,
    getSysConf,
    getUserInfo,
    getRequestId,
    refreshRequestId,
    singlePointLogin,
    http,
} from './utils';
import ScreenLocker from './components/ScreenLocker';
import microfrontendLayout from './microfrontend-layout.html';

const getImportMap = function () {
    var importMap = new Map();
    Array.prototype.slice
        .call(document.querySelectorAll('script[type="systemjs-importmap"]'))
        .forEach(item => {
            const o = JSON.parse(item.innerText)['imports'];
            for (let p in o) {
                importMap.set(p, o[p]);
            }
        });
    return importMap;
};

// 设置主题
const changePrimary = function (color = '#1890ff') {
    ConfigProvider.config({
        theme: {
            primaryColor: color,
        },
    });
};
// init
const initApp = function () {
    const importMap = getImportMap();
    let routes = constructRoutes(microfrontendLayout, {
        props: { appConf: window.appConf },
        loaders: {
            loading: `<div style="
                width: 100%;
                height: 100%; 
                display: flex;
                align-items: center;
                justify-content: center;
            ">
                <div class="ant-spin ant-spin-spinning" aria-live="polite" aria-busy="true">
                    <span class="ant-spin-dot ant-spin-dot-spin">
                        <i class="ant-spin-dot-item"></i>
                        <i class="ant-spin-dot-item"></i>
                        <i class="ant-spin-dot-item"></i>
                        <i class="ant-spin-dot-item"></i>
                    </span>
                </div>
            </div>`,
            // loading: Loading
        },
    });
    if (window.appConf.env === 'dev') {
        // @ts-ignore
        localStorage.setItem('devtools', true);
    } else {
        // @ts-ignore
        localStorage.setItem('devtools', false);
    }
    let applications = constructApplications({
        routes,
        loadApp({ name }) {
            return System.import(name);
        },
    });
    if (window.self !== window.top) {
        applications = applications.filter(
            item =>
                item.name !== '@gf-tech/header' &&
                item.name !== '@gf-tech/sidebar'
        );
    }
    const layoutEngine = constructLayoutEngine({ routes, applications });
    applications.forEach(item => {
        if (item.name === '@kc/pes') {
            registerApplication({
                name: '@kc/pes',
                app: () =>
                    import(
                        /* webpackIgnore: true */
                        localStorage.getItem('import-map-override:@kc/pes') ||
                            importMap.get(item.name)
                    ),
                activeWhen: ['/pes'],
            });
        } else {
            registerApplication(item);
        }
    });
    layoutEngine.activate();
    start();
};
// events
const initEvents = function () {
    EventBus.subscribe('app:reloadAll', () => {
        setTimeout(() => {
            const mountedAppNames = getMountedApps();
            mountedAppNames.forEach(item => {
                if (item !== '@gf-tech/header') {
                    unloadApplication(item);
                }
            });
        }, 10);
    });
    EventBus.subscribe('app:ws-connect', () => {
        ws.connect();
    });
    EventBus.subscribe('app:ws-disconnect', () => {
        ws.close();
    });
    EventBus.subscribe('app:toggleTheme', color => changePrimary(color));
};
// onload => something
const loadHandler = function () {
    document.title = window.appConf.appName;
    window.addEventListener('load', function () {
        if (window.self !== window.top) {
            document.body.className = 'inner-iframe';
        }
    });
};
// loginHandler
const loginHandler = async function () {
    const token = store.get('token');
    const params = _location.getParams();
    const authCode = store.get('authCode');
    const pathname = window.location.pathname;
    if (/^\/public/.test(window.location.pathname)) {
        return;
    }
    if (/^\/pes/.test(window.location.pathname)) {
        return;
    }
    if (params.code && params.code !== authCode) {
        await singlePointLogin({
            code: params.code,
            attrs: params.attrs,
            loginMode: 'CUSTOM_MH',
        });
        store.set('authCode', params.code);
    } else if (token) {
        await getUserInfo();
    } else if (['/aisfzx', '/cdr/patient-detail'].includes(pathname)) {
        const inaUser = new URL(window.location.href).searchParams.get(
            'inaUser'
        );
        const inaApp = new URL(window.location.href).searchParams.get('inaApp');
        if (!inaApp || !inaUser) return;
        const res: any = await http.post(
            '/msx-authentication/api/v1/login/login',
            {
                loginMode: 'MOCK',
                // userId: uid,
            },
            {
                headers: {
                    ina: location.href,
                    'ina-user': inaUser ?? '',
                    'ina-app': inaApp ?? '',
                },
            }
        );
        store.set('token', res.token);
        store.set('hideHeader', true);
        store.set('currentAppIndex', 0);
        store.set('userInfo', res.userInfo);
    }
};

const main = async function () {
    loadHandler();
    try {
        if (window.location.pathname === '/') {
            window.location.href = '/index/home';
        }
        getSysConf();
        await getRequestId();
        await loginHandler();
        initApp();
        initEvents();
        refreshRequestId();
        changePrimary(store.get('theme') || '#0AB2C1');
        window.screenLocker = new ScreenLocker();
    } catch (err) {
        console.error(err);
    }
};

main();
