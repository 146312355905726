import { message } from 'antd';
import { Log, EventBus } from '@gf-tech/utils';

import { store } from '../utils';
import { MsgCommand } from './constant';

type TReciveMessage = {
    userId: string; // 用户id
    command: string; // 命令
    content: string; // 内容
    requestId: string; // 请求id
    extendData: string; // 扩展数据
    authorization: string; // 授权登陆凭证
};

class WS {
    constructor(wsHost: string) {
        this.wsHost = wsHost;

        this.onmessage = this.onmessage.bind(this);
    }

    wsHost = '';

    instance: WebSocket;

    private sendToken() {
        const token = store.get('token');
        this.sendMsg(token);
    }

    // 全局消息，大部分都为全局消息
    private globalMsgHandler(msg: TReciveMessage): boolean {
        if (msg.command === MsgCommand.LOGOUT) {
            message.error('用户权限发生变更，请重新登陆！');
            setTimeout(() => {
                store.remove('token');
                window.location.href = '/login';
            }, 1500);
            return false;
        }
        if (msg.command === MsgCommand.REFRESH_INDEX) {
            EventBus.dispatch('app:refresh-index', null);
            return false;
        }
        if (msg.command === MsgCommand.SYS_MSG) {
            EventBus.dispatch('app:refresh-msgbox', null);
            return false;
        }
    }

    // connect
    connect() {
        const _self = this;
        if (this.instance) this.instance = null;
        this.instance = new WebSocket(this.wsHost);

        this.instance.onopen = function () {
            Log.log('ws连接成功！');
            _self.sendToken();
        };

        this.instance.onmessage = this.onmessage;

        this.instance.onerror = function () {
            Log.log('ws连接发生错误，3s后重连...');
            setTimeout(() => _self.connect(), 3000);
        };

        this.instance.onclose = function () {
            Log.log('ws已断开连接！');
        };
    }

    onmessage(e: MessageEvent<any>) {
        if (process.env.NODE_ENV === 'development') {
            Log.log('message:', e);
        }
        const msg: TReciveMessage = JSON.parse(e.data);
        const next = this.globalMsgHandler(msg);
        if (next) EventBus.dispatch(msg.command, msg.content);
    }

    sendMsg(msg: string) {
        this.instance.send(msg);
    }

    close() {
        this.instance.close();
    }
}

const ws = new WS(window.appConf.wsHost);

export default ws;
