import { TUser } from '@gf-tech/types';
import { Store, Http, menuDispose } from '@gf-tech/utils';
import { TReqLogin } from '../models';
import ws from '../ws';

const Apis = {
    dev: 'http://dev.guanfangyiliao.com',
    test: 'https://test.guanfangyiliao.com',
    demo: 'http://yanshi.guanfangyiliao.com',
};

const store = new Store(window.appConf.namespace);

const env = store.get('env') || window.appConf.env;
if (env) {
    window.appConf.apiHost = Apis[env];
    window.appConf.wsHost = Apis[env].replace('http', 'ws') + '/ws';
}

export { store };

export const http = new Http().create({
    baseURL: window.appConf.apiHost,
    store: store,
});

// 获取系统配置
export const getSysConf = function () {
    http.get<{ strongPwd: boolean }>(
        '/msx-authentication/api/v1/login/globalConfig'
    ).then(res => store.set('sysConf', res));
};
// 获取requestId
export const getRequestId = function () {
    return http
        .get<string>('/api/v1/common/requestId', {
            showErr: false,
        })
        .then(res => {
            store.set('requestId', res);
        })
        .catch(err => {
            console.error(err);
        });
};
// 获取用户信息
export const getUserInfo = function () {
    return http
        .get<TUser>('/msx-authentication/api/v1/login/userinfo')
        .then(res => {
            res.resList = menuDispose(res.resList, res.saasCode);
            store.set('userInfo', res);
            ws.connect();
        })
        .catch(err => {
            console.error(err);
        });
};
// 刷新requestId
export const refreshRequestId = function () {
    setTimeout(() => {
        http.get<string>('/api/v1/common/requestId').then(res => {
            store.set('requestId', res);
        });
        refreshRequestId();
    }, 5 * 60 * 1000);
};
// 单点登录
export const singlePointLogin = function (data: TReqLogin) {
    return http
        .post<TReqLogin, { userInfo: TUser; token: string }>(
            '/msx-authentication/api/v1/login/login',
            data
        )
        .then(res => {
            store.set('token', res.token);
            store.set('userInfo', res.userInfo);
            ws.connect();
        })
        .catch(err => {
            console.error(err);
        });
};
// 解锁密码校验
export const unlockValidate = function (password: string) {
    return http.get<boolean>(
        '/msx-authentication/api/v1/basic/unlockValidate',
        {
            params: { password },
        }
    );
};
// 注销登录
export const logout = function () {
    return http.get('/msx-authentication/api/v1/login/logout');
};
